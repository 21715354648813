import React from "react";
export default function Landing() {
    return (
        <header
            id="home"
            className="page bg-secondary text-primary min-vh-100 d-flex align-items-center justify-content-center"
        >
            <div className="text-center">
                <h1 style={{ fontSize: "5vw" }}>Daniel Budworth-Mead</h1>
                <p style={{ fontSize: "3vw" }}>Video Games Programmer</p>
            </div>
        </header>
    );
}
